import React, {
  PropsWithChildren,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
//https://claritydev.net/blog/typing-react-context-in-typescript
export const AppContext = createContext<{
  file: string;
  setFile: (file: string) => void;
  showSidebar: boolean;
  setShowSidebar: (sidebar: boolean) => void;
  width: number;
  showWholeSidebar: boolean;
  setShowWholeSidebar: (sidebar: boolean) => void;
  language: string;
  setLanguage: (language: string) => void;
}>({
  file: "hello_world.html",
  setFile: (file: string) => {},
  showSidebar: true,
  setShowSidebar: (sidebar: boolean) => {},
  width: 0,
  showWholeSidebar: true,
  setShowWholeSidebar: (sidebar: boolean) => {},
  language: "fi",
  setLanguage: (language: string) => {},
});

export const AppProvider = ({ children }: PropsWithChildren<{}>) => {
  const [file, setFile] = useState("hello_world.html");
  const [showSidebar, setShowSidebar] = useState(true);
  const [showWholeSidebar, setShowWholeSidebar] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [language, setLanguage] = useState("fi");

  const getVw = useCallback(() => {
    if (typeof window !== "undefined") {
      return Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
    }
    return 0;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const w = getVw();
      if (w < 768) {
        if (w < 535) {
          if (showWholeSidebar) {
            setShowWholeSidebar(false);
          }
        } else {
          if (showSidebar) {
            setShowSidebar(false);
          }
        }
      } else if (w >= 768) {
        if (!showWholeSidebar || !showSidebar) {
          setShowSidebar(true);
          setShowWholeSidebar(true);
        }
      }
      setWidth(w);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showWholeSidebar, showSidebar]);

  //check what url on load
  useEffect(() => {
    const url = window.location.pathname;
    if (url.includes("cv")) {
      setFile("CV.txt");
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        file,
        setFile,
        showSidebar,
        setShowSidebar,
        width,
        showWholeSidebar,
        setShowWholeSidebar,
        language,
        setLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
